import React, { useContext } from 'react';
import Style from './commands.tailwind';
import { Button } from 'components/lib';
import { useTranslate } from 'app/translations';
import { AuthContext } from 'app/auth';

const TicketCommands = ({ ticket }) => {
    const { _t } = useTranslate();
    const url = ticket.integration_data.url;
    const {
        user: { permission },
    } = useContext(AuthContext);
    return (
        <div className={Style.container}>
            {url && permission !== 'contact' && (
                <Button
                    text={_t('view.on') + ' ' + ticket.ticket_category.integration_data.service}
                    small
                    action={() => window.open(url, '_blank')}
                    color="blue"
                    className={Style.btn}
                />
            )}
        </div>
    );
};

export default TicketCommands;
