/***
 *
 *   USER
 *   shows the current user
 *   if user belongs to more than one account they can switch accounts here
 *
 **********/

import { useContext, useState, memo } from "react";
import {
  HoverNav,
  Button,
  ViewContext,
  useProjectContext,
} from "components/lib";
import { AuthContext } from "app/auth";
import { useTranslate } from "app/translations";
import Style from "./selector.tailwind.js";
import LetteredAvatar from "lettered-avatar";
import { useNavigate } from "components/lib";
import { useQueryClient } from "react-query";

const form = (createProject) => ({
  logo: {
    label: "Logo",
    type: "logo",
    required: false,
    placeholder: "",
  },
  name: {
    label: createProject("project.name"),
    type: "text",
    required: true,
  },
  privacy: {
    label: createProject("project.privacy.label"),
    type: "select",
    required: true,
    options: [
      { value: "private", label: createProject("project.privacy.private") },
      { value: "public", label: createProject("project.privacy.public") },
    ],
    default: "private",
  },
});

export const ProjectSelector = memo((props) => {
  const navigate = useNavigate();
  const { _t } = useTranslate();
  const queryClient = useQueryClient();
  const viewContext = useContext(ViewContext);
  const context = useContext(AuthContext);
  const { permission } = context?.user;
  const {
    projects,
    switchProject,
    selectAllProjects,
    isAllProjectSelected,
    selectedProject,
  } = useProjectContext();

  const [navOpen, setNavOpen] = useState(false);

  const hasEditPermission = permission !== "contact" && permission !== "user";

  if (!projects) return null;

  function createProject() {
    viewContext.modal.show(
      {
        title: _t("project.create.label"),
        form: form(_t),
        buttonText: _t("save"),
        url: `/api/project`,
        method: "POST",
      },
      () => {
        queryClient.refetchQueries("project_users");
        window.location.reload();
      }
    );
  }

  return (
    <div className={Style.selector}>
      {projects.length > 0 ? (
        <div
          className={Style.project}
          onClick={() => {
            if (!hasEditPermission) return;
            const projectUrl = selectedProject?.id
              ? `/app/settings/projects/${selectedProject?.id}`
              : "/app/settings/projects";
            navigate(projectUrl);
          }}
        >
          <h4 className={Style.name}>
            {selectedProject?.name || _t("project.all")}
          </h4>
          <div
            onMouseEnter={(e) => setNavOpen(true)}
            onMouseLeave={(e) => setNavOpen(false)}
          >
            <LetteredAvatar
              name={selectedProject?.name || _t("project.all")}
              imgSrc={selectedProject?.file_path}
              options={{ imgClass: "w-10 h-10 object-cover" }}
            />
          </div>
        </div>
      ) : (
        hasEditPermission && (
          <Button
            small
            text={_t("project.create.label")}
            action={createProject}
          />
        )
      )}

      {projects.length > 0 && (
        <HoverNav align={"right"} isOpen={navOpen}>
          {projects.map((project) => {
            return (
              <Button
                key={project.id}
                text={project.name}
                action={() => {
                  switchProject(project.id);
                }}
                style={{ backgroundColor: "red" }}
                className={`shadow-[0] ${
                  selectedProject?.id === project.id ? "!font-bold" : ""
                }`}
              />
            );
          })}
          <Button
            text={_t("project.all")}
            action={selectAllProjects}
            className={`shadow-[0] ${isAllProjectSelected ? "!font-bold" : ""}`}
          />
          {hasEditPermission && (
            <Button
              text={_t("project.create.new")}
              action={createProject}
              className={"shadow-[0]"}
            />
          )}
        </HoverNav>
      )}
    </div>
  );
});
