/***
*
*   HOME LAYOUT
*   Main website layout
*
**********/
import { HomeNav, Footer, useLocation } from 'components/lib';

export function HomeLayout(props){

  const location = useLocation();
  const transparent = location?.pathname === '/' ? true : false;

  return (
    <>
      <main className='home'>

        <HomeNav transparent={ transparent } />
        { <props.children {...props.data }/> }

      </main>
      {/* <Footer /> */}
    </>
  );
}
