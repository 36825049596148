import React from 'react';
import Style from './media.tailwind';
import { useTranslate } from 'app/translations';
import { Icon } from 'components/lib';

const TicketAttachment = ({ medias }) => {
    const { _t } = useTranslate();
    const noMedias = medias.length === 0;
    const multipleMedias = medias.length > 1;
    const wrapperStyle = noMedias 
        ? `${Style.wrapper} h-[160px]`
        : `${Style.wrapper} h-[320px]`;

    return (
        <div className={wrapperStyle}>
            <h3 className={Style.title}>
                <Icon image="camera" className={Style.icon} size={20} />
                {_t('ticket.history.media')}{multipleMedias && "s"}
            </h3>
            <div className={Style.container}>
                {noMedias ? <p>{_t('ticket.history.noMediaAttached')}</p> : (
                    medias.map((media, index) => (
                        <div key={index} className={Style.media}>
                            <a target="_blank" rel="noreferrer" href={media.file_path} className={Style.link} title={media.image.original_name}>
                                <img className={Style.img} src={media.file_path} alt={media.image.original_name} />
                                    <div className={Style.overlay}>
                                        <Icon
                                            image={'eye'}
                                            color={'white'}
                                            size={18}
                                        />
                                    </div>
                            </a>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default TicketAttachment;
