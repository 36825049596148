const Style = {
    container: 'flex flex-col bg-white shadow-xl rounded-2xl min-w-[280px] my-3 border border-main-300/50',
    list: 'overflow-y-auto scrollbar px-4 py-2',
    top: 'flex justify-between items-center p-4 pb-1',
    title: 'text-lg font-semibold',
    action: 'flex',
    actionBtn: 'transition duration-300 ease-in-out hover:bg-slate-200 rounded-2xl p-1',
};

export default Style;
