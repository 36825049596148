export const findTicketStatus = (ticket) => {
    const {
        ticket_category: { status_template },
    } = ticket;

    let ticketStatus = null;
    status_template.forEach((item) => {
        item.statuses.forEach((status) => {
            if (status.id === ticket.integration_data.status) {
                ticketStatus = item;
                return;
            }
        });
    });
    return ticketStatus;
};
