const Style = {

  stat: 'relative text-center min-h-max px-4',
  icon: 'absolute top-1/2 left-4 -translate-y-1/2',
  value: 'text-2xl font-bold flex justify-center items-center',
  valueLabel: 'text-sm font-semibold text-slate-400 ml-1',
  change: 'absolute top-1/3 right-5 text-sm text-center -translate-y-1/2',
  changeIcon: 'absolute top-[0.1em] -right-5',
  label: 'text-sm text-slate-400',

}

export default Style;