import React, { useEffect, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useApiContext } from 'app/api';
import { Button, Loader, TicketForm, Card } from 'components/lib';
import Style from './project.tailwind';
import LetteredAvatar from 'lettered-avatar';
import { navigateRoot } from 'utilities/routes';
import { _t } from 'app/translations';
import { AuthContext } from 'app/auth';

export const AccountProjectPage = () => {
    const { name } = useParams();
    const { callApi } = useApiContext();
    const { user } = useContext(AuthContext);
    const [showForm, setShowForm] = useState(true);

    const projectQuery = useQuery('project', () =>
        callApi({
            url: `/api/projectByName?name=${name}`,
            auth: false,
        })
    );

    const project = projectQuery.data?.data;
    const ticketCategories = project?.ticketCategories;

    useEffect(() => {
        if (projectQuery.isFetched) {
            // If the project is not found, redirect to root 404
            if (projectQuery.data?.status !== 200 || !project) {
                navigateRoot('/404');
            }
        }
    }, [projectQuery.data]);

    const onTicketSubmit = () => {
        setShowForm(false);
    };

    return (
        <div>
            {projectQuery.isLoading ? (
                <div className="flex justify-center p-12">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className={Style.container}>
                        <LetteredAvatar
                            name={project.name}
                            imgSrc={project.file_path}
                            options={{ imgClass: 'w-24 h-24 object-cover border' }}
                        />
                        <h1 className={Style.title}>{project.name}</h1>
                        {project.privacy === 'private' && (
                            <Button
                                text={_t('dashboard.goto')}
                                color="blue"
                                url={user ? '/app/tickets' : `/project/${name}/magic`}
                            />
                        )}
                    </div>
                    {showForm && (
                        <Card
                            restrictWidth
                            shadow
                            center
                            title={_t('request.make')}
                            className="border mb-12">
                            <TicketForm categories={ticketCategories} callback={onTicketSubmit} />
                        </Card>
                    )}

                    {!showForm && (
                        <div className="flex justify-center pb-96">
                            <Button
                                text={_t('request.makeAnother')}
                                color="green"
                                action={() => setShowForm(true)}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
