import React from 'react';
import Style from './nav.tailwind';
import { Button } from 'components/lib';
import { useApiContext } from 'app/api';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useTranslate } from 'app/translations';



const TicketDetailsNav = ({ ticket }) => {
    const { _t } = useTranslate();
    const [active, setActive] = React.useState(0);
    const { callApi } = useApiContext();
    const queryClient = useQueryClient();

    const navItems = [
        {
            name: _t('overview'),
            active: true,
        },
    ];
    
    const {
        ticket_category: {
            integration_data: { service },
        },
    } = ticket;

    const { mutate: syncTicketData, isLoading } = useMutation(
        'syncTicketData',
        () =>
            callApi({
                url: `/api/ticket/${ticket.id}/sync?service=${service}`,
                method: 'POST',
            }),
        {
            onSuccess: () => {
                queryClient.refetchQueries('tickets');
            },
        },
    );
    return (
        <ul className={Style.nav}>
            <div className={Style.navItems}>
                {navItems.map((item, index) => (
                    <li
                        key={index}
                        className={`${Style.item} ${
                            active === index ? 'bg-slate-200 text-main-500' : ''
                        }`}
                        onClick={() => setActive(index)}>
                        {item.name}
                    </li>
                ))}
            </div>

            <Button
                className="border flex items-center justify-center w-10 h-10 rounded-full"
                iconClassName={`text-white ${isLoading ? 'animate-spin' : ''}`}
                color="blue"
                position="relative"
                icon="refresh-cw"
                action={syncTicketData}
            />
        </ul>
    );
};

export default TicketDetailsNav;
