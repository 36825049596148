import 'react-app-polyfill/ie9';
import { createRoot } from 'react-dom/client';
import App from './app/app';
import { ApiProvider } from 'app/api';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TranslateProvider } from 'app/translations';
import { AuthProvider } from 'app/auth';
import * as Sentry from '@sentry/react';

const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: [`${process.env.REACT_APP_PRODUCTION_DOMAIN}/api/`],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
    <QueryClientProvider client={queryClient}>
        <TranslateProvider>
            <ApiProvider>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </ApiProvider>
        </TranslateProvider>
    </QueryClientProvider>,
);