import React, { useContext, useState, useEffect } from 'react';
import { Form, Animate, ViewContext } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
export const TicketForm = (props) => {
    const { _t } = useTranslate();
    const { user } = useContext(AuthContext);
    const viewContext = useContext(ViewContext);
    const { categories } = props;
    const queryClient = useQueryClient();
    const [searchParams] = useSearchParams();

    const firsName = searchParams.get('fn');
    const lastName = searchParams.get('ln');
    const email = searchParams.get('e');

    const firstForm = {
        first_name: {
            label: _t('firstName.label'),
            type: 'text',
            required: true,
            value: user?.first_name || firsName || '',
            step: 1,
        },
        last_name: {
            label: _t('lastName.label'),
            type: 'text',
            required: true,
            value: user?.last_name || lastName || '',
            step: 1,
        },
        email: {
            label: _t('email.label'),
            type: 'email',
            required: true,
            value: user?.email || email || '',
            step: 1,
        },
        ticket_category_id: {
            label: _t('category'),
            type: 'select',
            required: true,
            options: categories?.map((category) => ({
                label: category.name,
                value: category.id,
            })),
            default: props.category === 'all' ? null : props.category ?? '',
            step: 1,
        },
    };

    const secondForm = {
        priority: {
            label: _t('priority.label'),
            type: 'select',
            required: true,
            options: [
                { label: _t('priority.low'), value: 'low' },
                { label: _t('priority.medium'), value: 'medium' },
                { label: _t('priority.high'), value: 'high' },
            ],
            default: '',
            step: 2,
        },
        subject: {
            label: _t('subject'),
            type: 'text',
            required: true,
            value: '',
            step: 2,
        },
        description: {
            label: _t('description'),
            type: 'textarea',
            required: false,
            value: '',
            step: 2,
        },
        files: {
            label: _t('file.multiple'),
            type: 'file',
            required: false,
            value: '',
            step: 2,
        },
    };

    const [baseForm, setBaseForm] = useState(firstForm);
    const formData = {
        ...baseForm,
        ...secondForm,
    };
    useEffect(() => {
        setBaseForm({
            ...baseForm,
            ticket_category_id: {
                ...baseForm.category,
                options: categories?.map((category) => ({
                    label: category.name,
                    value: category.id,
                })),
            },
        });
    }, [categories]);

    return (
        <Animate>
            <Form
                data={formData}
                withStep
                cancel={
                    props.cancel
                        ? () => {
                              viewContext.modal.hide(true);
                          }
                        : null
                }
                callback={(e) => {
                    viewContext.notification.show(_t('request.success'), 'success', true, 'toast');
                    viewContext.modal.hide(true);
                    props.callback && props.callback(e);

                    queryClient.refetchQueries('tickets');
                }}
                url="/api/ticket"
                auth={false}
                buttonText={_t('send')}
                method="POST"
            />
        </Animate>
    );
};
