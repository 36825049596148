import React from 'react';
import { Icon } from 'components/lib';
import { formatFileName } from 'utilities/utils';

const Style = {
    link: 'text-blue-500 underline',
    containerCommon: 'relative w-24 h-24 overflow-hidden flex items-center justify-center',
    imageContainer: 'rounded-2xl',
    fileContainer: 'border-2 border-main-300 rounded-2xl',
    image: 'object-cover w-full h-full transition-all duration-500 ease-in-out transform hover:scale-105 rounded-2xl',
    overlay: 'absolute inset-0 bg-black opacity-0 hover:opacity-50 flex items-center justify-center transition-all duration-500 ease-in-out',
    overlayFile: 'absolute inset-0 bg-black opacity-0 hover:opacity-50 flex items-center justify-center transition-all duration-500 ease-in-out',
    icon: 'text-white text-2xl',
    iconFile: 'text-black text-2xl',
    filesContainer: 'flex flex-wrap gap-2',
}; 

export const CommonAttachment = ({attachment}) => {
    return (
        <div className={Style.filesContainer}>
            <a href={attachment.url} target="_blank" rel="noreferrer" title={attachment.title}>
                {attachment.type === 'image' ? (
                    <div className={`${Style.containerCommon} ${Style.imageContainer}`}>
                        <img src={attachment.url} alt="attachment" className={Style.image} title={attachment.title} />
                        <div className={Style.overlay}>
                            <Icon
                                image={'eye'}
                                color={'white'}
                                size={18}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={`group ${Style.containerCommon} ${Style.fileContainer}`}>
                        <Icon
                            image={'file'}
                            color={'black'}
                            size={18}
                            className="opacity-100 group-hover:opacity-20"
                        />
                        <div className={Style.overlayFile}>
                            <Icon
                                image={'eye'}
                                color={'white'}
                                size={18}
                            />
                        </div>
                    </div>
                )}
                <span className={Style.link}>{formatFileName(attachment.title)}</span>
            </a>             
        </div>
    );
};

