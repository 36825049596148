import React from 'react';
import { useTranslate } from 'app/translations';

import { firstLetterToUpperCase } from 'utilities/utils';

export const Status = ({ historyData }) => {
    const { _t } = useTranslate();
    const { before, after } = historyData;

    const status = (status) => {
        return  <strong className="text-sm text-secondary-500">{firstLetterToUpperCase(status)}</strong>
    }

    const renderDetails = () => {
        if(!before){
            return(
                <p>
                    {_t('ticket.history.event.status.changed').toLowerCase()} {status(after)}
                </p>
            )
        }else{
            return(
                <p>
                    {_t('ticket.history.event.status.changedToFrom').toLowerCase()} {status(before)} {_t('to').toLowerCase()} {status(after)}
                </p>
            )
        }

    }

    return (
        <div >
            {renderDetails()}
        </div>
    );
};
