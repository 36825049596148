import React, { useMemo } from 'react';
import { Stat } from 'components/lib';
import Style from './stats.tailwind';
import { useTranslate } from 'app/translations';
import * as dayjs from 'dayjs';

const calcDiffTime = (date1, date2) => {
    const diffTime = Math.abs(new Date(date1) - new Date(date2)); // Calculate the difference in milliseconds
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Calculate the difference in days
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Calculate the difference in hours

    return diffDays + diffHours / 24;
};

const isDateOffsetFrom = (date1, date2, offset = 0) => {
    return dayjs(date1).isSame(
        offset > 0 ? dayjs(date2).add(offset, 'day') : dayjs(date2).subtract(offset, 'day'),
        'day',
    );
};

const findCurrStatus = (ticket) => {
    const { integration_data, ticket_category } = ticket;
    const { status } = integration_data;

    let currStatus = null;
    // find valetify status from ticket category status_template
    ticket_category?.status_template.forEach(({ key, statuses }) => {
        const statusFound = statuses.find((item) => item.id === status);
        if (statusFound) {
            currStatus = key;
            return;
        }
    });

    return currStatus;
};

const calcAverageCompletionTime = (tickets, offset = 0) => {
    let completedTicketCount = 0;
    const result =
        tickets.data?.reduce((acc, ticket) => {
            let { histories } = ticket;
            const currStatus = findCurrStatus(ticket);

            if (currStatus !== 'completed' && currStatus !== 'closed') {
                return acc;
            }

            if (offset) {
                // get histories that are within offset
                histories = ticket.histories.filter((history) => {
                    return isDateOffsetFrom(dayjs(), history.date_created, offset);
                });
            }

            let statusChangedToCompletedHistory = histories.reverse().find((history) => {
                if (
                    history.action_type === 'status' &&
                    history.history_data?.after === 'completed'
                ) {
                    return true;
                } else {
                    return false;
                }
            });

            if (!statusChangedToCompletedHistory) {
                // check for closed one
                statusChangedToCompletedHistory = histories.reverse().find((history) => {
                    if (
                        history.action_type === 'status' &&
                        history.history_data?.after === 'Closed'
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }

            const creationDate = ticket.date_created;
            const completionDate = statusChangedToCompletedHistory?.date_created;

            if (!completionDate) {
                return acc;
            } else {
                completedTicketCount++;
                return acc + calcDiffTime(completionDate, creationDate);
            }
        }, 0) / completedTicketCount;
    return result;
};

const calcAveragePendingTime = (tickets, offset = 0) => {
    let pendingTicketCount = 0;
    const result =
        tickets.data?.reduce((acc, ticket) => {
            let { histories } = ticket;

            if (offset) {
                // get histories that are within offset
                histories = ticket.histories.filter((history) => {
                    return isDateOffsetFrom(dayjs(), history.date_created, offset);
                });
            }

            // find history that left pending status
            let history = histories.reverse().find((history) => {
                if (
                    history.action_type === 'status' &&
                    history.history_data?.before === 'pending'
                ) {
                    return true;
                } else {
                    return false;
                }
            });

            if (!history) {
                return acc;
            }

            const creationDate = ticket.date_created;
            const pendingDate = history?.date_created;

            if (!pendingDate) {
                return acc;
            } else {
                pendingTicketCount++;
                return acc + calcDiffTime(pendingDate, creationDate);
            }
        }, 0) / pendingTicketCount;
    return result;
};

export const TicketStats = ({ tickets }) => {
    const { _t } = useTranslate();

    const numOfActiveTickets = useMemo(() => {
        return tickets.data?.filter((ticket) => {
            const currStatus = findCurrStatus(ticket);

            return currStatus !== 'closed';
        });
    }, [tickets.data]);

    const todayActiveTickets = useMemo(() => {
      return tickets.data?.filter((ticket) => {
        const todayHistories = ticket.histories.filter((history) => {
          return isDateOffsetFrom(dayjs(), history.date_created);
        });

        // get latest status change history
        const activeHistory = todayHistories.reverse().find((history) => {
          if (
            (history.action_type === "status" &&
              history.history_data?.after !== "Closed") ||
            history.action_type === "created"
          ) {
            return true;
          } else {
            return false;
          }
        });
        return activeHistory;
      });
    }, [tickets.data]);

    const yesterdayActiveTickets = useMemo(() => {
      return tickets.data?.filter((ticket) => {
        const prevHistories = ticket.histories.filter((history) => {
          // dayjs
          const today = dayjs();
          const historyDate = dayjs(history.date_created);
          return today.diff(historyDate, "day") > 0;
        });
        // get active history
        const activeHistory = prevHistories.reverse().find((history) => {
          if (
            (history.action_type === "status" &&
              history.history_data?.after !== "Closed") ||
            history.action_type === "created"
          ) {
            return true;
          } else {
            return false;
          }
        });
        return activeHistory;
      });
    }, [tickets.data]);

    const newTickets = useMemo(() => {
        return tickets.data?.filter((ticket) => {
            const { date_created } = ticket;

            const today = dayjs();
            const ticketDate = dayjs(date_created);
            return isDateOffsetFrom(today, ticketDate);
        });
    }, [tickets.data]);

    const completedTodayTickets = useMemo(() => {
        return tickets.data?.filter((ticket) => {
            const { histories } = ticket;
            const currStatus = findCurrStatus(ticket);

            if (currStatus !== 'completed' && currStatus !== 'closed') {
                return false;
            }

            const todayHistories = histories.filter((history) => {
                return isDateOffsetFrom(dayjs(), history.date_created);
            });

            // find latest history find status update
            const statusChangedHistory = todayHistories.reverse().find((history) => {
                if (
                    history.action_type === 'status' &&
                    (history.history_data?.after === 'completed' ||
                        history.history_data?.after === 'closed')
                ) {
                    return true;
                } else {
                    return false;
                }
            });

            return statusChangedHistory;
        });
    }, [tickets.data]);

    const averageCompletionTime = useMemo(() => {
        return calcAverageCompletionTime(tickets) || 0;
    }, [tickets.data]);

    const averageYesterdayCompletionTime = useMemo(() => {
        return calcAverageCompletionTime(tickets, -1) || 0;
    }, [tickets.data]);

    const averagePendingTime = useMemo(() => {
        return calcAveragePendingTime(tickets) || 0;
    }, [tickets.data]);

    const averageYesterdayPendingTime = useMemo(() => {
        return calcAveragePendingTime(tickets, -1) || 0;
    }, [tickets.data]);

    return (
        <section className={Style.container}>
            <Stat
                cardClass={'mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2'}
                loading={false}
                label={_t('ticket.stats.number')}
                icon="number"
                value={numOfActiveTickets?.length}
            />
            <Stat
                cardClass={'mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2'}
                loading={false}
                label={_t('ticket.stats.new')}
                icon="number"
                value={newTickets?.length}
            />
            <Stat
                cardClass={'mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2'}
                loading={false}
                label={_t('ticket.stats.completedToday')}
                icon="number"
                value={completedTodayTickets?.length}
            />
            <Stat
                cardClass={'mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2'}
                loading={isNaN(averageCompletionTime) || isNaN(averagePendingTime)}
                label={_t('ticket.stats.averageCompletion')}
                icon="number"
                value={averageCompletionTime === 0 ? 0 : averageCompletionTime?.toFixed(2)}
                valueLabel={_t('days')}
                isPositive={false}
                change={(averageCompletionTime - averageYesterdayCompletionTime).toFixed(2)}
            />
            <Stat
                cardClass={'mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2'}
                label={_t('ticket.stats.averagePending')}
                loading={isNaN(averageCompletionTime) || isNaN(averagePendingTime)}
                icon="number"
                value={averagePendingTime === 0 ? 0 : averagePendingTime?.toFixed(2)}
                valueLabel={_t('days')}
                isPositive={false}
                change={(averagePendingTime - averageYesterdayPendingTime).toFixed(2)}
            />
        </section>
    );
};
