import { Account } from 'views/settings';
import { Profile } from 'views/settings/profile';
import { Organization } from 'views/settings/organization';
import { Billing } from 'views/settings/billing';
import { Upgrade } from 'views/settings/upgrade';
import { Users } from 'views/settings/users/users';
import { Password } from 'views/settings/password';
import { TwoFA } from 'views/settings/2fa';
import { APIKeyList } from 'views/settings/apikey/list';
import { APIKeyEditor } from 'views/settings/apikey/edit';
import { Projects } from 'views/settings/projects/projects';
import { EditProject } from 'views/settings/projects/edit';
import { _t } from '../app/translations';
import EditUser from 'views/users/edit';
import Contacts from 'views/users/contacts';
import Mails from 'views/mails/mails';

const Routes = [
    {
        path: '/app/settings',
        view: Account,
        layout: 'app',
        permission: 'user',
        title: _t('settings.title'),
    },
    {
        path: '/app/settings/profile',
        view: Profile,
        layout: 'app',
        permission: 'user',
        title: _t('profile.title'),
    },
    // {
    //     path: '/app/settings/contacts',
    //     view: Contacts,
    //     layout: 'app',
    //     permission: 'user',
    //     title: _t('contact.label.multiple'),
    // },
    {
        path: '/app/settings/projects',
        view: Projects,
        layout: 'app',
        permission: 'admin',
        title: _t('project.title.multiple'),
    },
    {
        path: '/app/settings/projects/:id',
        view: EditProject,
        layout: 'app',
        permission: 'owner',
        title: _t('project.title.one'),
    },
    {
        path: '/app/settings/organization',
        view: Organization,
        layout: 'app',
        permission: 'owner',
        title: _t('organization.title'),
    },
    {
        path: '/app/settings/password',
        view: Password,
        layout: 'app',
        permission: 'user',
        title: _t('password.change'),
    },
    // {
    //     path: '/app/settings/2fa',
    //     view: TwoFA,
    //     layout: 'app',
    //     permission: 'user',
    //     title: _t('2fa.manage'),
    // },
    // {
    //     path: '/app/settings/billing',
    //     view: Billing,
    //     layout: 'app',
    //     permission: 'owner',
    //     title: _t('billing.label'),
    // },
    // {
    //     path: '/app/settings/upgrade',
    //     view: Upgrade,
    //     layout: 'app',
    //     permission: 'owner',
    //     title: _t('upgrade.label'),
    // },
    {
        path: '/app/settings/users',
        view: Users,
        layout: 'app',
        permission: 'admin',
        title: _t('user.label.multiple'),
    },
    {
        path: '/app/settings/users/:userId',
        view: EditUser,
        layout: 'app',
        permission: 'admin',
        title: _t('user.label.multiple'),
    },
    // {
    //     path: '/app/settings/apikeys',
    //     view: APIKeyList,
    //     layout: 'app',
    //     permission: 'developer',
    //     title: _t('api.key.one'),
    // },
    // {
    //     path: '/app/settings/apikeys/create',
    //     view: APIKeyEditor,
    //     layout: 'app',
    //     permission: 'developer',
    //     title: _t('api.key.one'),
    // },
    // {
    //     path: '/app/settings/apikeys/edit',
    //     view: APIKeyEditor,
    //     layout: 'app',
    //     permission: 'developer',
    //     title: _t('api.key.one'),
    // },
    // {
    //     path: '/app/mails',
    //     view: Mails,
    //     layout: 'app',
    //     permission: 'user',
    //     title: 'Mails'
    // }
];

export default Routes;
