import React, { useContext } from 'react';
import { Button, Toggle, ViewContext, TicketForm } from 'components/lib';
import Style from './controls.tailwind';
import { useTranslate } from 'app/translations';
import { useLiveQuery } from 'components/hooks/websocket';
import { useProjectContext } from 'components/lib';
import { AuthContext } from 'app/auth';

export const TicketControls = ({ setSearchParams, searchParams, getSearchParams }) => {
    const { _t } = useTranslate();
    const viewContext = useContext(ViewContext);
    const {
        user: { permission },
    } = useContext(AuthContext);

    const { selectedProject, isAllProjectSelected } = useProjectContext();
    const categories = useLiveQuery(
        'ticket_categories',
        { project_id: selectedProject?.id },
        {
            enabled: !!selectedProject?.id,
        },
    );

    const currCategory = categories.data?.find((category) => {
        return category.id === searchParams.get('c');
    });

    const updateFilterParams = (params) => {
        let newParams = getSearchParams();

        newParams = { ...newParams, ...params };

        // Remove empty params
        Object.keys(newParams).forEach((key) => {
            if (!newParams[key]) {
                delete newParams[key];
            }
        });

        setSearchParams(newParams);
    };

    const sortParamsLength = Object.keys(getSearchParams()).filter((key) => {
        return key === 'by';
    }).length;

    const filterParamsLength = Object.keys(getSearchParams()).filter((key) => {
        return key === 'priority' || key === 'assignee';
    }).length;

    const categoryAssignees = currCategory?.integration_data?.users || [];

    return (
        <section className={Style.container}>
            {/* <Toggle 
            firstLabel={_t('status.one')} 
            secondLabel={_t('overview')} 
            onChange={null} /> */}
            <div className={Style.actions}>
                <Button
                    text={_t('sorts')}
                    small
                    icon="sliders"
                    iconColor="white"
                    iconButton
                    count={sortParamsLength}
                    color="blue"
                    className={'px-[15px] py-[7px]'}
                    action={() => {
                        viewContext.modal.show(
                            {
                                title: _t('ticket.sort.title'),
                                form: {
                                    by: {
                                        type: 'select',
                                        label: _t('ticket.filter.by.label'),
                                        required: false,
                                        options: [
                                            {
                                                label: _t('ticket.filter.by.date_created'),
                                                value: 'date_created',
                                            },
                                            {
                                                label: _t('ticket.filter.by.due_date'),
                                                value: 'due_date',
                                            },
                                            {
                                                label: _t('ticket.filter.by.priority'),
                                                value: 'priority',
                                            },
                                        ],
                                        default: searchParams.get('by') || 'date_created',
                                    },
                                    order: {
                                        type: 'select',
                                        label: _t('ticket.filter.order.label'),
                                        required: true,
                                        options: [
                                            { label: _t('ticket.filter.order.asc'), value: 'asc' },
                                            {
                                                label: _t('ticket.filter.order.desc'),
                                                value: 'desc',
                                            },
                                        ],
                                        default: searchParams.get('order') || 'asc',
                                    },
                                },
                                buttonText: _t('save'),
                            },
                            (data) => {
                                updateFilterParams(data);
                            },
                        );
                    }}
                />
                <Button
                    text={_t('filters')}
                    small
                    color="blue"
                    icon="filter"
                    iconColor="white"
                    iconButton
                    count={filterParamsLength}
                    className={'px-[15px] py-[7px]'}
                    action={() => {
                        viewContext.modal.show(
                            {
                                title: _t('ticket.filter.title'),
                                form: {
                                    priority: {
                                        type: 'select',
                                        label: _t('priority.label'),
                                        options: [
                                            { label: _t('none'), value: '' },
                                            { label: _t('priority.low'), value: 'low' },
                                            { label: _t('priority.medium'), value: 'medium' },
                                            { label: _t('priority.high'), value: 'high' },
                                        ],
                                        default: searchParams.get('priority'),
                                    },
                                    ...(permission !== 'contact' &&
                                        !isAllProjectSelected && {
                                            assignee: {
                                                type: 'select',
                                                label: _t('assignee.one'),
                                                options: [
                                                    { label: _t('none'), value: '' },
                                                    ...categoryAssignees.map((user) => {
                                                        return {
                                                            label: user.name,
                                                            value: user.email,
                                                        };
                                                    }),
                                                ],
                                                default: searchParams.get('assignee'),
                                            },
                                        }),
                                },
                                buttonText: _t('save'),
                            },
                            (data) => {
                                updateFilterParams(data);
                            },
                        );
                    }}
                />
                {currCategory && (
                    <Button
                        text={_t('ticket.create.label')}
                        small
                        className={'px-[15px] py-[7px]'}
                        action={() => {
                            viewContext.modal.show({
                                title: _t('ticket.create.title'),
                                component: (
                                    <TicketForm
                                        cancel
                                        categories={categories.data}
                                        category={searchParams.get('c')}
                                    />
                                ),
                            });
                        }}
                    />
                )}
            </div>
        </section>
    );
};
