import { useState, createContext, useContext } from 'react';
import { useApiContext } from 'app/api';
import { useQuery, useQueryClient } from 'react-query';
import { AuthContext } from 'app/auth';
import { navigateRoot } from 'utilities/routes';
import { useLiveQuery } from 'components/hooks/websocket';

export const ProjectContext = createContext(null);

export const ProjectContextProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { callApi } = useApiContext();
    const queryClient = useQueryClient();

    const [isAllProjectSelected, setIsAllProjectSelected] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    if (!user) {
        navigateRoot('/signin');
    }

    const {
        data: projects,
        status,
        loading,
    } = useLiveQuery('projects', { user: user.user_id }, {
        enabled: !!user?.onboarded
    });
    
    useQuery('project_users', () => callApi({ url: `/api/project-users` }), {
        enabled: !!projects?.length > 0,
        onSuccess: (res) => {
            const projectUser = res.data.find((item) => item.is_default && item.user_id === user.user_id);
            if(projectUser) {
                const project = projects.find((item) => item.id === projectUser.project_id);
                setSelectedProject(project);                
            }else{
                setIsAllProjectSelected(true);
                setSelectedProject(null);
            }
        }
    });

    const selectAllProjects = async () => {
        const res = await callApi({ url: '/api/project/all', method: 'PUT' });
        if(res.status === 200) {
            setIsAllProjectSelected(true);
            setSelectedProject(null);
            updateProjectUsers();
        }
    };

    const switchProject = async (id) => {
        const res = await callApi({ url: '/api/project', method: 'PATCH', data: { id } });
        if(res.status === 200){
            setIsAllProjectSelected(false);
            setSelectedProject(projects.find((item) => item.id === id));
            updateProjectUsers(id);
        }
    };

    // Update project users wihout refetching
    const updateProjectUsers = async (id) => {
        queryClient.setQueryData('project_users', (oldData) => {
          const newData = oldData.data.map((item) => {
            if (item.project_id === id) {
              return {
                ...item,
                is_default: true,
              };
            } else {
              return {
                ...item,
                is_default: item.project_id !== null ? false : item.is_default,
              };
            }
          });
          return {
            ...oldData,
            data: newData,
          };
        });
    };      

    const updateAllProjects = (bool) => {
        setIsAllProjectSelected(bool);
    };

    const value = {
        projects: projects,
        switchProject,
        selectAllProjects,
        isAllProjectSelected,
        updateAllProjects,
        selectedProject,
        loading,
        status,
    };

    return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
};

export const useProjectContext = () => {
    return useContext(ProjectContext);
};
