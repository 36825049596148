const Style = {
    container: 'relative top-0 min-w-[180px] my-2 flex flex-col shadow-md bg-main-100/30 rounded-2xl',
    header: 'z-10 p-3',
    title: 'text-main-500 text-md font-semibold text-center', 
    border: 'w-full border-main-500/50 my-2',
    categoryList: 'flex flex-col space-y-2 mb-2 overflow-y-auto overflow-hidden max-h-[calc(100vh - 200px)] p-2',
    category: 'flex items-center m-1 py-2 px-3 text-main-500 text-md font-semibold w-full transition-all duration-300 rounded-2xl hover:bg-main-400/10',
    icon: 'text-main-500/80 cursor-pointer hover:text-main-500 hover:scale-125',
    btnContent: 'space-y-2 px-2 pb-3',
    btn: 'text-xm px-[2px] py-[7px] w-full',
};

export default Style;
