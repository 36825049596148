import { useState, useEffect, createContext, useContext } from 'react';
import i18next from 'i18next';
import { getUserCache } from 'utilities/cache';
import languages from '../languages/lang';

// get browser language
const browserLanguage = navigator.language.split('-')[0];
const localStorageLanguage = JSON.parse(localStorage.getItem('user'))?.language || browserLanguage;

i18next.init({
    lng: localStorageLanguage,
    fallbackLng: 'en',
    resources: {
        en: {
            translation: languages.en,
        },
        fr: {
            translation: languages.fr,
        },
        
    }
});

export const _t = i18next.t.bind(i18next);

// translate context
export const TranslateContext = createContext();

export function TranslateProvider(props) {
    // eslint-disable-next-line no-unused-vars
    const [ lang, setLang ] = useState(browserLanguage);
    
    useEffect(() => {
        const user = getUserCache();

        if (user) {
            setLang(user.language);
            i18next.changeLanguage(user.language);
        } else {
            i18next.changeLanguage(browserLanguage);
        }
    }, []);

    return (
        <TranslateContext.Provider
            value={{
                _t: _t,
                locale: lang,
            }}
            {...props}
        />
    );
}

export const useTranslate = () => {
    return useContext(TranslateContext);
};
