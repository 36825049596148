import { Badge } from 'components/lib';
import React from 'react';
import Style from './ticket.tailwind';
import { useSearchParams } from 'react-router-dom';

export const priorityColor = {
    high: `bg-red-100 text-red-500`,
    medium: `bg-orange-100 text-orange-500`,
    low: `bg-green-100 text-green-500`,
};

export const TicketItem = (props) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const { data, onItemClick } = props;
    const { subject, text, priority, due_date, is_synced, is_archived, number, } = data;

    return (
        <div
            className={Style.container}
            onClick={() => {
                searchParams.set('id', number);
                setSearchParams(searchParams);
                onItemClick && onItemClick();
            }}>
            <div className={Style.top}>
                <span className={Style.text}>{subject || text}</span>
                {(!is_synced || is_archived) && (
                    <Badge
                        icon="alert-circle"
                        sizeIcon={14}
                        className={`p-2 px-0 py-0 ${priorityColor['medium']}`}
                    />
                )}
            </div>
            <div className={Style.bottom}>
                <Badge
                    text={priority}
                    className={`w-fit py-[2px] text-[10px] ${priorityColor[priority]}`}
                />
               {due_date &&  <span className={Style.date}>{new Date(due_date).toLocaleDateString()}</span>}
            </div>
        </div>
    );
};
