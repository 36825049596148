/***
 *
 *   APP NAV
 *   Primary navigation used inside the main app component
 *
 *   PROPS
 *   type: fixed or popup (style of nav on mobile)
 *   items: array of objects containing label, link and icon (optional)
 *
 **********/

import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Icon, Avatar } from 'components/lib';
import './app.scss';

export function AppNav(props) {
    // state
    const [open, setOpen] = useState(false); // mobile is open
    const [expanded, setExpanded] = useState(false); // desktop is expanded

    return (
        <nav className={`sidebar popup ${open && 'open'} ${expanded && 'expanded'}`}>
            <Button
                icon={open ? 'x' : 'menu'}
                color={'dark'}
                size={20}
                className="btn-togglenav mt-5"
                action={(e) => setOpen(!open)}
            />
            
                <Avatar />
            
            <Button
                icon={expanded ? 'chevron-left' : 'chevron-right'}
                color={'light'}
                size={25}
                className="btn-toggle_expand text-main-500"
                action={(e) => setExpanded(!expanded)}
            />
            <section className="nav-links">
                {props.items?.map((item) => {
                    if (item.link) {
                        return (
                            <NavLink
                                key={item.label}
                                to={item.link}
                                onClick={() => setOpen(false)}
                                style={{ width: 100 / props.items.length + '%' }}>
                                {item.icon && (
                                    <Icon
                                        className="icon"
                                        image={item.icon}
                                        size={open ? 15 : 18}
                                        color={open ? 'dark' : 'light'}
                                    />
                                )}
                                {item.label && <span className="label">{item.label}</span>}
                            </NavLink>
                        );
                    }

                    return (
                        <div key={item.label} onClick={item.action} className="cursor-pointer">
                            {item.icon && (
                                <Icon
                                    className="icon"
                                    image={item.icon}
                                    size={open ? 15 : 18}
                                    color={open ? 'dark' : 'light'}
                                />
                            )}
                            {item.label && <span className="label">{item.label}</span>}
                        </div>
                    );
                })}
            </section>
        </nav>
    );
}
