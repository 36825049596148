import React, { useContext } from 'react';
import Style from './general.tailwind';
import { useTranslate } from 'app/translations';
import { Badge, Icon } from 'components/lib';
import { AuthContext } from 'app/auth';

export const priorityColor = {
    high: `bg-red-100 text-red-500`,
    medium: `bg-orange-100 text-orange-500`,
    low: `bg-green-100 text-green-500`,
};
const TicketGeneralInfo = ({ ticket, status }) => {
    const { _t } = useTranslate();
    const {
        user: { permission },
    } = useContext(AuthContext);

    const formatDescription = (data) => {
        if (!data) return null;
        // for multiples spaces add a br
        data = data.replace(/  +/g, '<br />');

        return data;
    };
    return (
        <div className={Style.container}>
            <h3 className={Style.title}>{_t('request.information')}</h3>
            <div className={Style.item}>
                <span className={Style.itemTitle}>{_t('number')}</span>
                {ticket.number}
            </div>
            {permission !== 'contact' && (
                <div className={Style.item}>
                    <span className={Style.itemTitle}>{_t('assignee.to')}</span>
                    <div className={Style.assignees}>
                        {ticket.ticket_assignee.length ? (
                            ticket.ticket_assignee.map((assignee, index) => {
                                return (
                                    <p key={index}>
                                        {assignee.first_name} {assignee.last_name}
                                    </p>
                                );
                            })
                        ) : (
                            <p>-</p>
                        )}
                    </div>
                </div>
            )}
            <div className={Style.item}>
                <span className={Style.itemTitle}>{_t('priority.label')}</span>
                <Badge
                    text={_t(`priority.${ticket.priority}`)}
                    className={`w-fit text-[14px] ${priorityColor[ticket.priority]}`}
                />
            </div>
            <div className={Style.item}>
                <span className={Style.itemTitle}>{_t('status.one')}</span>
                {status?.label}
            </div>
            <div className={Style.item}>
                <span className={Style.itemTitle}>{_t('categories.label.one')}</span>
                <p>{ticket.ticket_category.name}</p>
            </div>
            <div className={Style.item}>
                <span className={Style.itemTitle}>{_t('due.on')}</span>
                {ticket.due_date ? (
                    <p>{new Date(ticket.due_date).toLocaleDateString()}</p>
                ) : (
                    <p>-</p>
                )}
            </div>
            <div className={`flex flex-col py-2 items-start`}>
                <span className={`${Style.itemTitle} m-0`}>{_t('description')}</span>
                <div
                    className="min-h-[100px]"
                    dangerouslySetInnerHTML={{
                        __html: formatDescription(ticket.description),
                    }}></div>
            </div>
            {!ticket.is_synced && (
                <div className={`flex flex-col py-2 items-start`}>
                    <div className="p-4 bg-orange-100 text-orange-500 rounded-xl w-fll flex flex-col items-center justify-center text-center">
                        <Icon image="alert-circle" size={24} />
                        <p>{_t('integration.notSyncWarning')}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TicketGeneralInfo;
