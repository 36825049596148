import { useContext, useState } from 'react';
import { Link, ClassHelper, HoverNav, Button, ViewContext } from 'components/lib';
import { AuthContext } from 'app/auth';
import Style from './avatar.tailwind.js';
import { useApiContext } from 'app/api';
import { useQuery } from 'react-query';
import LetteredAvatar from 'lettered-avatar';

export function Avatar(props) {
    // context
    const { callApi } = useApiContext();
    const authContext = useContext(AuthContext);
    const viewContext = useContext(ViewContext);
    const accountId = authContext.user.account_id;
    const [navOpen, setNavOpen] = useState(false);
    // fetch
    const user = useQuery('user', () => callApi({ url: '/api/user' }));

    const account = authContext.user.accounts.find((account) => account.id === accountId);
    const hasMultipleAccounts = user?.data?.data.accounts?.length > 1;

    const { permission } = authContext.user;

    const logoStyle = ClassHelper(Style, props);

    const linkClass = permission === 'owner' ? logoStyle : `${logoStyle} cursor-default`;
    const linkUrl = permission === 'owner' ? '/app/settings/organization' : null;

    return (
        <Link url={linkUrl} className={linkClass}>
            {user.data && (
                <div
                    onMouseEnter={(e) => hasMultipleAccounts && setNavOpen(true)}
                    onMouseLeave={(e) => hasMultipleAccounts && setNavOpen(false)}>
                    <LetteredAvatar
                        name={account.name}
                        imgSrc={account.file_path}
                        options={{ imgClass: 'w-12 h-12 object-cover' }}
                    />
                    {hasMultipleAccounts && (
                        <HoverNav className={Style.hoverNav} isOpen={navOpen}>
                            {authContext.user.accounts.map((account) => {
                                return (
                                    <Button
                                        key={account.id}
                                        text={account.name}
                                        action={() => {
                                            viewContext.setLoading(true);
                                            authContext.switchAccount(account.id);
                                        }}
                                        className={'shadow-[0]'}
                                    />
                                );
                            })}
                        </HoverNav>
                    )}
                </div>
            )}
        </Link>
    );
}
