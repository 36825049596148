/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import { useContext } from 'react';
import { AuthContext} from 'app/auth';
import { AppNav, Header, ProjectSelector } from 'components/lib';
import Style from './app.module.scss';
import { useTranslate } from 'app/translations.js';

export function AppLayout(props) {
    const { _t } = useTranslate();

    // context & style
    const context = useContext(AuthContext);
    const { permission } = context?.user;
    
    return (
        <div className={Style.wrapper}>
            <AppNav
                items={[
                    { label: _t('ticket.label.multiple'), icon: 'clipboard', link: '/app/tickets' },
                    ...(permission === 'owner'? [ {
                        label: _t('contact.label.multiple'),
                          icon: 'users',
                          link: '/app/contacts'                    
                    }]: []),
                    { label: _t('settings.label'), icon: 'settings', link: '/app/settings' },
                    // { label: _t('help.label'), icon: 'help-circle', link: '/app/help' },
                    { label: _t('signout.label'), icon: 'log-out', action: context.signout },
                ]}
            />

            <main className={Style.app}>
                <Header title={props.title}>
                    <ProjectSelector />
                </Header>
                <div className={Style.content}>
                {<props.children {...props.data} />}
                </div>
            </main>
        </div>
    );
}
