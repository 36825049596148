import React from 'react';
import Style from './status.tailwind';
import { TicketItem } from 'components/lib';
import { useTranslate } from 'app/translations';
import './status.scss';

export const TicketStatus = (props) => {
    const { item } = props;
    const { _t } = useTranslate();
    return (
        <div className={Style.container + ' scrollbar'}>
            <div className={Style.top}>
                <h3 className={Style.title}>{_t(`ticket.category.status.${item.key}`)}</h3>
                <div className={Style.action}></div>
            </div>
            <div className={Style.list}>
                {props.tickets.map((ticket, index) => {
                    return <TicketItem key={index} data={ticket} status={item.label} />;
                })}
            </div>
        </div>
    );
};
