/***
 *
 *   FORM
 *   Self-validating form that accepts an object for construction
 *   Read the full documentation on object formatting
 *   https://docs.usegravity.app/ui/form
 *
 *   PROPS
 *   data: the object containing your form data
 *   callback: function to be executed on successful submit
 *   url: url to send the form to (optional)
 *   method: HTTP request type
 *   redirect: url to redirect to after a successful submit (optional)
 *   buttonText: submit button text
 *   cancel: true/false to toggle a cancel button (optional)
 *   hideSubmit: true/false to toggle the submit button (optional)
 *   injectedData: object to be sent with the form (optional)
 *   withStep: true/false to render a form with multiple steps, you need to add a step property(number) to each field.
 *   isLoading: true/false to display a loading spinner
 *   preventModalClose: true/false to prevent the modal from closing when the form is submitted
 **********/

import { useState, useEffect, useContext } from 'react';

import {
    FormHeader,
    TextInput,
    NumberInput,
    EmailInput,
    URLInput,
    PhoneInput,
    DateInput,
    PasswordInput,
    HiddenInput,
    CardInput,
    Select,
    FormLink,
    Switch,
    FileInput,
    Fieldset,
    Button,
    ViewContext,
    useNavigate,
    ClassHelper,
    DomainInput,
    LogoFileInput,
    TextListInput,
    Loader,
    SelectGroup,
} from 'components/lib';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Style from './form.tailwind.js';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api.js';

function Form(props) {
    // context & state
    const context = useContext(ViewContext);
    const [form, setForm] = useState(null);
    const [currStep, setCurrStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [fileStore, setFileStore] = useState([]);
    const [processCreditCard, setProcessCreditCard] = useState(false);

    const navigate = useNavigate();
    const { callApi } = useApiContext();
    const { _t } = useTranslate();
    let valid = true;

    // inputs map
    const Inputs = {
        text: TextInput,
        textarea: TextInput,
        email: EmailInput,
        number: NumberInput,
        url: URLInput,
        date: DateInput,
        hidden: HiddenInput,
        phone: PhoneInput,
        password: PasswordInput,
        creditcard: CardInput,
        radio: Fieldset,
        select: Select,
        selectGroup: SelectGroup,
        checkbox: Fieldset,
        selector: Fieldset,
        switch: Switch,
        header: FormHeader,
        link: FormLink,
        file: FileInput,
        domain: DomainInput,
        logo: LogoFileInput,
        textList: TextListInput,
    };

    useEffect(() => {
        // if the form is valid and using
        // live updates, refresh the form
        if (valid && props.updateOnChange) {
            // TODO: This bypass the file upload when using updateOnChange
            setForm(props.data);
        }

        // otherwise, only init if no form set
        else if (!form) {
            let data = { ...props.data };

            // init credit card
            if (data?.token) {
                data?.plan?.default === 'free'
                    ? setProcessCreditCard(false)
                    : setProcessCreditCard(true);
            }

            setForm(data);
        }
    }, [props, form, valid]);

    if (!form) return false;

    function update(input, value, valid) {
        let data = { ...form };

        // is it a file?
        if (value.length && value[0].name && value[0].type && value[0].size) {
            if (!fileStore[input]?.length) fileStore[input] = [];

            const newFiles = { ...fileStore };
            value.forEach((file) => {
                // add or delete the file
                if (file.data && !fileStore[input].find((x) => x.name === file.name)) {
                    newFiles[input].push(file);
                } else if (!file.data && input === 'logo') {
                    newFiles[input] = null;
                } else if (!file.data) {
                    newFiles[input].splice(
                        newFiles[input].findIndex((x) => x.name === file.name),
                        1,
                    );
                }
            });

            data[input].value = newFiles[input];
            data[input].valid = valid;
            setFileStore(newFiles);
        } else {
            // update input value & valid state
            data[input].value = value;
            data[input].valid = valid;

            // hide credit card input when selecting free plan
            if (props.data.token) {
                if (input === 'plan' && value === 'free') {
                    setProcessCreditCard(false);
                } else if (input === 'plan' && value !== 'free') {
                    setProcessCreditCard(true);
                }
            }
        }
        setForm(data);

        if (props.onChange) {
            props.updateOnChange && props.onChange({ input: input, value: value, valid: valid });

            // TODO: Bypass the file skyping from useEffect
            props.updateOnChangeFixed &&
                props.onChange({ input: input, value: value, valid: valid });
        }

        props.submitOnChange && submit();

        props.submitOnConfirm &&
            (() => {
                props.beforeSubmit();
                submit();
            })();
    }

    function validate() {
        // loop over each input and check it's valid
        // show error if input is requured and value is
        // blank, input validation will be executed on blur

        let errors = [];
        let data = { ...form };

        // loop the inputs
        for (let input in data) {
            if (props.withStep) {
                // Validate only data of current step
                if (data[input].step !== currStep) {
                    continue;
                }
            }
            // validate credit card
            if (input === 'token') {
                if (processCreditCard && data.token.value.error) {
                    data.token.valid = false;
                    errors.push(false);
                } else {
                    data.token.valid = true;
                }
            } else {
                // standard input
                let inp = data[input];
                if (inp.value === undefined && inp.default) {
                    data[input].value = inp.default;
                }

                if (inp.required) {
                    if (!inp.value || inp.value === 'unselected') {
                        inp.valid = false;
                        errors.push(false);
                    }
                }

                if (inp.valid === false) {
                    errors.push(false);
                }
            }
        }

        if (errors.length) {
            // form isn't valid
            valid = false;
            setForm(data);
            return false;
        } else {
            // form is valid
            return true;
        }
    }

    async function submit() {
        // submit the form
        setLoading(true);

        const fieldsToIgnore = [];
        // check for fields to ignore
        for (const [key, value] of Object.entries(form)) {
            if (value.ignoreOnSubmit) {
                fieldsToIgnore.push(form[key].name);
            }
        }

        let data = { ...form };

        // create the credit card token
        if (processCreditCard) {
            const cardElement = await props.elements.getElement(CardElement);
            const token = await props.stripe.createToken(cardElement);
            data.token.value = token.token;
        }

        // is the form valid?
        if (!validate()) {
            setLoading(false);
            return false;
        }

        // optimise data for server
        for (let input in form) {
            if (processCreditCard && input === 'token') {
                // procress credit card
                data[input] = form[input].value;
            } else if (input !== 'header') {
                // process single input & ignore headers
                data[input] = form[input].value;
            }
        }

        delete data.header;

        // submit the form or execute callback
        if (!props.url) {
            props.callback && props.callback(data);
            !props.preventModalClose && context.modal.hide(true);

            return false;
        }

        // remove fields to ignore
        fieldsToIgnore.forEach((key) => {
            delete data[key];
        });

        try {
            let formData = new FormData(),
                headers = {};
            if (Object.keys(fileStore).length) {
                headers['Content-Type'] = 'multipart/form-data';

                for (let key in data) {
                    // append files
                    if (Array.isArray(data[key]) && data[key][0].hasOwnProperty('data')) {
                        for (let i = 0; i < data[key].length; i++) {
                            formData.append(key, data[key][i].data);
                        }
                    } else {
                        // append text values
                        formData.append(key, data[key]);
                    }
                }
                data = formData;
            }

            // inject data only if no image is uploaded to prevent empty form data
            if (props.injectedData && !Object.keys(fileStore).length) {
                data = {
                    ...data,
                    ...(props.injectedData ?? {}),
                };
            }

            let res = await callApi({
                method: props.method,
                url: props.url,
                data: data,
                headers: {
                    ...headers,
                    ...props.headers,
                },
                auth: props.auth ?? true,
            });

            // check for 2-factor payment requirement
            if (res.data.requires_payment_action) {
                const stripeRes = await props.stripe.handleCardPayment(res.data.client_secret);

                if (stripeRes.error) {
                    setLoading(false);
                    context.handleError(stripeRes.error.message);
                    return false;
                } else {
                    // re-send the form
                    data.stripe = res.data;
                    res = await callApi({
                        method: props.method,
                        url: props.url,
                        data: data,
                    });
                }
            }

            // finish loading
            setLoading(false);
            setCurrStep(1);
            // close the modal
            !props.preventModalClose && context.modal.hide(false, res.data);

            if (res.status !== 200) {
                context.handleError(res);
                return false;
            }

            // callback?
            if (props.callback) props.callback(res);

            // redirect?
            if (props.redirect) navigate(props.redirect);
        } catch (err) {
            // handle error
            setLoading(false);
            context.modal.hide(true);

            // show error on input
            if (err.response?.data?.inputError) {
                let data = { ...form };
                const input = err.response.data.inputError;
                data[input].valid = false;
                data[input].errorMessage = err.response.data.message;
                valid = false;
                setForm(data);
                return false;
            } else {
                console.log(err);
                // general errors handled by view
                context.handleError(err);
            }
        }
    }

    let inputsToRender = [];
    let numOfSteps = 0;

    const formStyle = ClassHelper(Style, {
        ...props,
        ...{
            loading: props.loading || loading,
        },
    });

    // map the inputs
    Object.keys(form).map((name) => {
        // get the values for this input
        const data = form[name];
        data.name = name;

        // check if the input should be rendered for form with multiple steps
        if (props.withStep) {
            // update the number of steps
            numOfSteps = data.step > numOfSteps ? data.step : numOfSteps;

            if (currStep === data.step) {
                inputsToRender.push(data);
            }
        } else {
            inputsToRender.push(data);
        }
        return inputsToRender;
    });

    const atFirstStep = currStep === 1;
    const atLastStep = currStep === numOfSteps;
    const shouldRenderCancel = !props.withStep ? true : atFirstStep;
    const shouldRenderSubmit = !props.withStep ? true : atLastStep;

    // render the form
    return (
        <form
            action={props.action}
            method={props.method}
            onSubmit={submit}
            className={formStyle}
            encType={fileStore.length && 'multipart/form-data'}
            noValidate>
            {inputsToRender.map((input) => {
                if (input.type === null) return false;

                if (!input.type) input.type = 'text';

                if (input.type === 'creditcard' && !processCreditCard) return false;

                const Input = Inputs[input.type];

                return (
                    <Input
                        key={input.name}
                        type={input.type}
                        form={props.name}
                        label={input.label}
                        className={input.class}
                        name={input.name}
                        value={input.value}
                        required={input.required}
                        valid={input.valid}
                        min={input.min}
                        max={input.max}
                        options={input.options}
                        default={input.default || input.value}
                        url={input.url}
                        text={input.text}
                        title={input.title}
                        accept={input.accept}
                        description={input.description}
                        readonly={input.readonly}
                        maxFileSize={input.maxFileSize}
                        handleLabel={input.handleLabel}
                        placeholder={input.placeholder}
                        errorMessage={input.errorMessage}
                        onChange={(name, value, valid) => {
                            update(name, value, valid);
                            if (input.onChange) input.onChange(value);
                        }}
                        complexPassword={input.complexPassword}
                        domain={input.domain}
                        textList={input.textList}
                        method={input.method}
                        onBlur={input.onBlur}
                        disabled={input.disabled}
                        slug={input.slug}
                    />
                );
            })}

            {props.isLoading && (
                <div className={Style.loading}>
                    <Loader relative />
                </div>
            )}
            {/* Prev button */}
            {props.withStep && currStep > 1 && (
                <Button
                    text={_t('previous')}
                    color={'blue'}
                    outline
                    action={() => {
                        // go to previous step
                        setCurrStep(currStep - 1);
                    }}
                    className={Style.button}
                />
            )}

            {/* Cancel button */}
            {shouldRenderCancel && props.cancel && (
                <Button
                    color={props.destructive ? 'green' : 'red'}
                    outline
                    text={_t('cancel')}
                    action={props.cancel}
                />
            )}

            {/* Next button */}
            {props.withStep && !atLastStep && (
                <Button
                    color={'green'}
                    loading={loading}
                    text={_t('next')}
                    fullWidth={!props.cancel}
                    action={() => {
                        // go to previous step
                        if (validate()) setCurrStep(currStep + 1);
                    }}
                    className={Style.button}
                />
            )}

            {/* Submit button */}
            {shouldRenderSubmit && props.buttonText && !props.hideSubmit && (
                <Button
                    color={props.destructive ? 'red' : 'green'}
                    loading={loading}
                    text={props.buttonText}
                    disabled={loading}
                    action={submit}
                    className={Style.button}
                    fullWidth={!props.cancel && !props.withStep}
                />
            )}
        </form>
    );
}

function PaymentForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    return <Form {...props} stripe={stripe} elements={elements} />;
}

export { Form, PaymentForm };
