/***
 *
 *   ACCOUNT
 *   Index page for settings functions
 *
 **********/

import React, { useContext } from 'react';
import { Animate, Grid, Card, Icon, Link, Loader } from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';
import { useApiContext } from 'app/api';
import { useQuery } from 'react-query';

export function Account(props) {
    const { _t } = useTranslate();
    const { callApi } = useApiContext();

    // Context
    const context = useContext(AuthContext);

    const user = useQuery('user', () => callApi({ url: '/api/user' }));
    const iconSize = 20;

    if (user.isLoading) return <Loader />;

    return (
        <Animate>
            <Grid cols="4">
            {context.permission?.owner && (
                <Card className='h-[200px]'>
                    <Icon image="briefcase" size={iconSize} />
                    <h2 className={Style.subtitle}>{_t('organization.label')}</h2>
                    <div>{_t('organization.updateInfo')}</div>
                    <Link url="/app/settings/organization" className={Style.link}>
                        {_t('update')}
                    </Link>
                </Card>
            )}
                <Card className='h-[200px]'>
                    <Icon image="user" size={iconSize} />
                    <h2 className={Style.subtitle}>{_t('profile.label')}</h2>
                    <div>{_t('profile.updateInfo')}</div>
                    <Link url="/app/settings/profile" className={Style.link}>
                        {_t('update')}
                    </Link>
                </Card>

                {context.permission?.admin && (
                <Card className='h-[200px]'>
                    <Icon image="user" size={iconSize} />
                    <h2 className={Style.subtitle}>{_t('project.label.multiple')}</h2>
                    <div>{_t('project.manage.title')}</div>
                    <Link url="/app/settings/projects" className={Style.link}>
                        {_t('manage')}
                    </Link>
                </Card>
                )}

                <Card className='h-[200px]'>
                    <Icon image="lock" size={iconSize} />
                    <h2 className={Style.subtitle}>{_t('password.label')}</h2>
                    <div>
                        {user?.data?.data['has_password']
                            ? _t('password.change')
                            : _t('password.create')}
                    </div>
                    <Link url="/app/settings/password" className={Style.link}>
                        {user?.data?.data['has_password'] ? _t('change') : _t('create')}
                    </Link>
                </Card>

                {/* <Card className='h-[200px]'>
                    <Icon image="shield" size={iconSize} />
                    <h2 className={Style.subtitle}>{_t('2fa.label')}</h2>
                    <div>{_t('organization.secure')}</div>
                    <Link url="/app/settings/2fa" className={Style.link}>
                        {user?.data?.data['2fa_enabled'] ? _t('manage') : _t('enable')}
                    </Link>
                </Card> */}

                {/* {context.permission?.owner && (
                    <Card className='h-[200px]'>
                        <Icon image="credit-card" size={iconSize} />
                        <h2 className={Style.subtitle}>{_t('billing.label')}</h2>
                        <div>{_t('billing.updatePlanOrCard')}</div>
                        <Link url="/app/settings/billing" className={Style.link}>
                            {_t('manage')}
                        </Link>
                    </Card>
                )} */}

                {/* {context.permission?.developer && (
                    <Card className='h-[200px]'>
                        <Icon image="settings" size={iconSize} />
                        <h2 className={Style.subtitle}>{_t('api.key.multiple')}</h2>
                        <div>{_t('api.manage')}</div>
                        <Link url="/app/settings/apikeys" className={Style.link}>
                            {_t('manage')}
                        </Link>
                    </Card>
                )} */}
                {/* {context.permission?.developer && (
                    <Card className='h-[200px]'>
                        <Icon image="mail" size={iconSize} />
                        <h2 className={Style.subtitle}>{'Mail'}</h2>
                        <div>Templates</div>
                        <Link url="/app/mails" className={Style.link}>
                            {'Email template'}
                        </Link>
                    </Card>
                )} */}
                {context.permission?.admin && (
                    <Card className='h-[200px]'>
                        <Icon image="users" size={iconSize} />
                        <h2 className={Style.subtitle}>{_t('user.label.multiple')}</h2>
                        <div>{_t('user.inviteOrganization')}</div>
                        <Link url="/app/settings/users" className={Style.link}>
                            {_t('user.add.multiple')}
                        </Link>
                    </Card>
                )}
            </Grid>
        </Animate>
    );
}

const Style = {
    subtitle: 'font-bold mt-4',
    link: 'text-blue-500 font-semibold mt-3 inline-block',
};
